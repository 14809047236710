import React, { useState, useEffect } from "react"; // Add useEffect
import { useAuth } from "../components/AuthProvider";
import TopBar from "../components/TopBar";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isAuthenticated, lastVisited } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(lastVisited);
    }
  }, [isAuthenticated, navigate, lastVisited]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null); // Clear any previous errors
    try {
      const loggedIn = await login(email, password);
      if (loggedIn) {
        navigate(lastVisited);
      }
    } catch (error) {
      // Handle the error by setting the error message
      setErrorMessage(error.message);
    }
  };

  console.log("LoginPage rendered");

  return (
    <div>
      <TopBar />
      <div className="flex bg-joule h-screen w-screen flex-col items-center space-y-6 mb-auto">
        <h2 className="text-center text-lg pt-8 font-century-gothic text-jouletext">
          LOGIN
        </h2>
        <div className="bg-white px-4 py-8 rounded-lg shadow-md sm:w-1/3">
          {/* Display error message if exists */}
          {errorMessage && (
            <div className="text-red-500 mb-4 text-center">{errorMessage}</div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="border-2 border-gray-300 w-full py-2 px-4 rounded-md"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="border-2 border-gray-300 w-full py-2 px-4 rounded-md"
            />
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="w-32 bg-white tracking-wide text-black font-bold rounded-lg border-black hover:bg-gray-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
              >
                <span className="mx-auto">Submit</span>
              </button>
            </div>
          </form>
          <div className="mt-4 text-center">
            <p>
              Don't have an account?{" "}
              <span
                className="cursor-pointer text-blue-500 hover:underline"
                onClick={() => navigate("/register")}
              >
                Register Here
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
