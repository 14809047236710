import React, { useState } from "react";

const ShippingMethodCell = ({ value, row, updateField, fieldToUpdate }) => {
  const [shippingMethod, setShippingMethod] = useState(value || "not set");

  const handleChange = async (e) => {
    const newShippingMethod = e.target.value;
    setShippingMethod(newShippingMethod);
    await updateField(row.original._id, fieldToUpdate, newShippingMethod);
  };

  return (
    <select
      value={shippingMethod}
      onChange={handleChange}
      className="border p-1 rounded"
    >
      <option value="not set">not set</option>
      <option value="wanda">wanda</option>
      <option value="nydalen">nydalen</option>
    </select>
  );
};

export default ShippingMethodCell;
