// Modal that opens when a user wants to mark a bike as returned (only cancellations)
// There should be checkboxes for items that should be returned
// If the user clicks "Mark as returned", the status of the bike should be updated to "Returned"
// There should be an external API call to Circuly to mark the bike as returned in that system
// If any of the boxes are not checked, an API post request should be made to our server side code
// This API request should open a new missing item report for the bike/customer in our system

import React, { useState } from "react";
import "../calendar.css";
import ReactModal from "react-modal";
import axios from "axios";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa"; // Import icons from react-icons

ReactModal.setAppElement("#root");

const ReturnConfirmationModal = ({
  isOpen,
  toggleModalVisibility,
  data,
  markAsReturned,
}) => {
  const [isMissing, setIsMissing] = useState(true);
  const [selectedItems, setSelectedItems] = useState({
    charger: false,
    adapter: false,
    lock: false,
    keysToLock: false,
    keysToBattery: false,
    retyreSkins: false,
    other: false,
  });
  const [note, setNote] = useState("");

  const handleCheckboxChange = (e) => {
    setSelectedItems({ ...selectedItems, [e.target.name]: e.target.checked });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const missingItems = Object.entries(selectedItems)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    try {
      if (isMissing && missingItems.length > 0) {
        await axios.post("/api/missing-items", {
          serialNumber: data.serialNumber,
          fullName: `${data.firstName} ${data.lastName}`,
          phone: data.phone,
          email: data.email,
          itemSkuName: data.itemSkuName,
          missingItems: missingItems,
          note,
          missingItemStatus: "open",
        });
      }

      // Make POST request to your backend, which then communicates with Circuly
      await axios.post(`/api/mark-as-returned/${data.serialNumber}`);

      // Update inventory item's operational status to "Repair-returned"
      await axios.put(`/api/inventory/${data.serialNumber}/operationalStatus`, {
        operationalStatus: "repair-returned",
      });

      // Mark the booking as returned
      await markAsReturned(data._id);

      toggleModalVisibility(); // Close the modal
    } catch (error) {
      console.error("Error processing return:", error);
    }
  };

  const isSubmitDisabled =
    isMissing && !Object.values(selectedItems).some(Boolean);

  const handleMissingSelect = (missing) => {
    setIsMissing(missing);
  };

  //Styled checkbox
  const Checkbox = ({ name, label }) => {
    return (
      <label className="flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          name={name}
          checked={selectedItems[name]}
          onChange={handleCheckboxChange}
          className="hidden" // Hide default checkbox
        />
        {selectedItems[name] ? (
          <FaCheckSquare className="text-joule text-xl" />
        ) : (
          <FaRegSquare className="text-gray-400 text-xl" />
        )}
        <span className="text-gray-700">{label}</span>
      </label>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleModalVisibility}
      contentLabel="Return Confirmation"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="bg-white shadow-lg rounded-lg px-10 py-8 mb-6 border-2 border-gray-600">
        <div className="mb-8">
          <h2 className="text-gray-900 text-2xl font-semibold text-center mb-6">
            Return Confirmation - Bike {data.serialNumber}
          </h2>
        </div>
        <form
          onSubmit={handleFormSubmit}
          className="bg-white rounded px-8 pt-6 pb-8"
        >
          {/* Custom buttons for missing items */}
          <div className="flex justify-around mb-10">
            <button
              type="button"
              onClick={() => handleMissingSelect(false)}
              className={`px-6 py-1 text-md rounded hover:bg-joule hover:text-white ${
                !isMissing
                  ? "bg-joule text-white shadow-black shadow-md"
                  : "bg-gray-400 text-white"
              }`}
            >
              Nothing is missing
            </button>
            <button
              type="button"
              onClick={() => handleMissingSelect(true)}
              className={`px-6 py-1 text-md rounded hover:bg-joule hover:text-white ${
                isMissing
                  ? "bg-joule text-white shadow-black shadow-md"
                  : "bg-gray-400 text-white"
              }`}
            >
              Something is missing
            </button>
          </div>

          {/* Checkboxes for specific missing items */}
          {/* Custom styled checkboxes */}
          {isMissing && (
            <div className="mb-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Checkbox name="charger" label="Charger" />
              <Checkbox name="adapter" label="Adapter" />
              <Checkbox name="lock" label="Lock" />
              <Checkbox name="keysToLock" label="2 sets of keys to lock" />
              <Checkbox
                name="keysToBattery"
                label="2 sets of keys to battery"
              />
              <Checkbox name="retyreSkins" label="Retyre Skins" />
              <Checkbox name="other" label="Other (specify in notes)" />
            </div>
          )}

          {/* Note section - only shown if something is missing */}
          {isMissing && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="note"
              >
                Note
              </label>
              <textarea
                className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
                id="note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Additional notes..."
              />
            </div>
          )}

          {/* Action buttons */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
            <button
              type="submit"
              className={`font-semibold py-2 px-6 rounded-lg shadow-md focus:outline-none focus:shadow-outline transition-colors ${
                isSubmitDisabled
                  ? "bg-gray-500 text-white" // Grey color when disabled
                  : "bg-joule text-white hover:bg-gray-900" // Normal styling
              }`}
              disabled={isSubmitDisabled}
            >
              Mark as Returned
            </button>
            <button
              type="button"
              className="bg-red-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:shadow-outline transition-colors"
              onClick={toggleModalVisibility}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default ReturnConfirmationModal;
