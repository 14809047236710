import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import axios from "axios";
import Table from "../components/tableComponent";
import ServiceCell from "../components/serviceRegistration";
import { DateCell } from "../components/calendarComp";
import ShippingMethodCell from "../components/shippingMethodCell";

function multiSelectFilter(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(row.values[columnIds]));
}

const NewOrders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);
  const [filterInput, setFilterInput] = useState(""); // User's input for filtering
  const [filterCategory, setFilterCategory] = useState("");
  const [filterOutFulfilled, setFilterOutFulfilled] = useState(false); // State to track the checkbox

  const toggleModal = useCallback((data = null) => {
    if (data) {
      setModalData({ ...data, source: "orders" });
    } else {
      setModalData(null); // This should hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value) => {
      try {
        const response = await axios.put(`/api/orders/${id}`, {
          [field]: value,
        });
        if (response.status === 200) {
          const updatedData = data.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          );
          setData(updatedData);
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [data]
  );

  const updateInventoryOperationalStatus = useCallback(
    async (serialNumber, newStatus) => {
      try {
        await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
          operationalStatus: newStatus,
        });
      } catch (error) {
        console.error("Error updating inventory operational status:", error);
      }
    },
    []
  );

  const markAsRepaired = async (id) => {
    await updateField(id, "orderStatus", "ready for customer");
  };

  //Function to delete an order
  const deleteOrder = useCallback(
    async (id) => {
      try {
        const response = await axios.delete(`/api/orders/${id}`);
        if (response.status === 200) {
          const updatedData = data.filter((item) => item._id !== id);
          setData(updatedData);
        }
      } catch (error) {
        console.error("Error deleting order:", error);
      }
    },
    [data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "orderShippingMethod",
        Cell: ({ value, row }) => (
          <ShippingMethodCell
            value={value}
            row={row}
            updateField={updateField}
            fieldToUpdate="orderShippingMethod"
          />
        ),
      },
      {
        Header: "Delivery Date",
        accessor: "orderTagDate",
        Cell: ({ row }) => (
          <DateCell
            date={row.original.orderTagDate}
            updateField={updateField}
            id={row.original._id}
            fieldToUpdate="orderTagDate"
          />
        ),
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      { Header: "Item", accessor: "variantTitle" },
      {
        Header: "Order status",
        accessor: "orderStatus",
        filter: multiSelectFilter,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            let newStatus = "";
            switch (row.original.orderStatus) {
              case "new":
                toggleModal(row.original);
                return;
              case "ready for customer":
                newStatus = "fulfilled";
                try {
                  // Fulfill order in Circuly using orderId from the order schema
                  await axios.post(
                    `/api/circuly-orders/${row.original.orderId}/fulfill`
                  );

                  // Update order's tag to "delivered" in Circuly using orderId
                  await axios.put(
                    `/api/circuly-orders/${row.original.orderId}/tag`,
                    { tag: "delivered" }
                  );

                  // Update inventory item's operational status to "With customer"
                  await updateInventoryOperationalStatus(
                    row.original.serialNumber,
                    "with customer"
                  );

                  // Update local order status
                  await updateField(row.original._id, "orderStatus", newStatus);
                } catch (error) {
                  console.error("Error processing order fulfillment:", error);
                }
                break;
              default:
                break;
            }
          };

          let buttonText = "";
          switch (row.original.orderStatus) {
            case "new":
              buttonText = "Start repair";
              break;
            case "ready for customer":
              buttonText = "Mark as fulfilled";
              break;
            default:
              return null; // No button for other statuses
          }
          return (
            <div className="flex items-center justify-between">
              <button
                onClick={handleActionClick}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline mr-2 w-32"
              >
                {buttonText}
              </button>
              <button
                onClick={() => deleteOrder(row.original._id)}
                className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
                style={{ padding: "0.25rem 0.5rem", lineHeight: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.225 4.811a1 1 0 011.414-1.414L12 7.757l4.36-4.36a1 1 0 111.414 1.414L13.414 9.17l4.36 4.36a1 1 0 01-1.414 1.414L12 10.586l-4.36 4.36a1 1 0 01-1.414-1.414l4.36-4.36-4.36-4.36z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          );
        },
      },
    ],
    [toggleModal, updateField, updateInventoryOperationalStatus, deleteOrder]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [{ id: "orderTagDate", desc: false }],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/orders");
        setData(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Handle changes in filter input
  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  const handleFilterOutFulfilledChange = (e) => {
    setFilterOutFulfilled(e.target.checked);
    if (e.target.checked) {
      // Set filter to show only 'new' and 'ready for customer' orders
      setFilter("orderStatus", ["new", "ready for customer"]);
    } else {
      // Reset the filter when checkbox is unchecked
      setFilter("orderStatus", "");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 mx-6">
      <h2 className="text-2xl font-semibold mb-4">New Orders</h2>

      {/* Filter Inputs */}
      <div className="flex justify-between mb-4 items-center">
        {/* Dropdown to select filter category/column */}
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <option value="">Select Filter Category...</option>
          {columns.map((column) => (
            <option value={column.accessor} key={column.accessor}>
              {column.Header}
            </option>
          ))}
        </select>

        {/* Checkbox to filter out 'fulfilled' orders */}
        <label className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            checked={filterOutFulfilled}
            onChange={handleFilterOutFulfilledChange}
            className="form-checkbox text-black rounded border-gray-300 bg-white focus:border-joule focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="text-sm font-medium text-gray-700">
            Hide fulfilled orders
          </span>
        </label>
      </div>

      {/* Text input for entering filter values */}
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder="Enter filter value..."
        className="border p-2 mt-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />

      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {modalData && (
        <ServiceCell
          isOpen={!!modalData}
          data={modalData}
          source={modalData.source} // Pass the source here
          toggleModalVisibility={() => toggleModal()}
          markAsRepaired={markAsRepaired}
        />
      )}
    </div>
  );
};

export default NewOrders;
