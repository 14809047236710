import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import axios from "axios";
import Table from "../components/tableComponent";
import ServiceCell from "../components/serviceRegistration";
import ReturnConfirmationModal from "../components/returnConfirmationModal";
import { DateCell } from "../components/calendarComp";
import ShippingMethodCell from "../components/shippingMethodCell";

function multiSelectFilter(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(row.values[columnIds]));
}

const ReturnsTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serviceModalData, setServiceModalData] = useState(null); // State for service modal
  const [returnModalData, setReturnModalData] = useState(null); // State for return modal
  const [filterInput, setFilterInput] = useState(""); // User's input for filtering
  const [filterCategory, setFilterCategory] = useState("");
  const [filterOutRepaired, setFilterOutRepaired] = useState(false); // State to track the checkbox

  const toggleServiceModal = useCallback((data = null) => {
    if (data) {
      setServiceModalData({ ...data, source: "returns" });
    } else {
      setServiceModalData(null); // This should hide the modal
    }
  }, []);

  const toggleReturnModal = useCallback((data = null) => {
    if (data) {
      setReturnModalData({ ...data, source: "returns" });
    } else {
      setReturnModalData(null); // This should hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value) => {
      try {
        const response = await axios.put(`/api/returns/${id}`, {
          [field]: value,
        });
        if (response.status === 200) {
          const updatedData = data.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          );
          setData(updatedData);
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [data]
  ); // data is a dependency here because it's used inside the function

  // Function to mark a booking as returned, this function is passed to the modal "ReturnConfirmationModal"
  const markAsReturned = async (id) => {
    await updateField(id, "returnStatus", "returned");
  };

  // Function to mark a booking as repaired, this function is passed to the modal "ServiceRegistration", and the ServiceCell component
  const markAsRepaired = async (id) => {
    await updateField(id, "returnStatus", "repaired");
  };

  //Function to delete a return
  const deleteReturn = useCallback(
    async (id) => {
      try {
        const response = await axios.delete(`/api/returns/${id}`);
        if (response.status === 200) {
          const updatedData = data.filter((item) => item._id !== id);
          setData(updatedData);
        }
      } catch (error) {
        console.error("Error deleting return:", error);
      }
    },
    [data]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        Cell: ({ row }) => {
          console.log("Row Data:", row.original); // Log each row's data
          return row.original.serialNumber;
        },
      },
      {
        Header: "Shipping Method",
        accessor: "returnShippingMethod",
        Cell: ({ value, row }) => (
          <ShippingMethodCell
            value={value}
            row={row}
            updateField={updateField}
            fieldToUpdate="returnShippingMethod"
          />
        ),
      },
      {
        Header: "Return Date",
        accessor: "returnDate",
        Cell: ({ row }) => (
          <DateCell
            date={row.original.returnDate}
            updateField={updateField}
            id={row.original._id}
            fieldToUpdate="returnDate"
          />
        ),
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      { Header: "Item", accessor: "variantTitle" },
      {
        Header: "Return Status",
        accessor: "returnStatus",
        filter: multiSelectFilter,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = () => {
            let newStatus = "";
            switch (row.original.returnStatus) {
              case "new":
                toggleReturnModal(row.original);
                return;
              case "returned":
                // Since the status change to 'repaired' is handled in the modal, we don't do anything here
                toggleServiceModal(row.original);
                return;
              default:
                break;
            }
            updateField(row.original._id, "returnStatus", newStatus);
          };

          let buttonText = "";
          switch (row.original.returnStatus) {
            case "new":
              buttonText = "Mark as returned";
              break;
            case "returned":
              buttonText = "Start repair";
              break;
            default:
              return null; // No button for other statuses
          }

          return (
            <div className="flex items-center justify-between">
              <button
                onClick={handleActionClick}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline mr-2 w-32"
              >
                {buttonText}
              </button>
              <button
                onClick={() => deleteReturn(row.original._id)}
                className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
                style={{ padding: "0.25rem 0.5rem", lineHeight: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.225 4.811a1 1 0 011.414-1.414L12 7.757l4.36-4.36a1 1 0 111.414 1.414L13.414 9.17l4.36 4.36a1 1 0 01-1.414 1.414L12 10.586l-4.36 4.36a1 1 0 01-1.414-1.414l4.36-4.36-4.36-4.36z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          );
        },
      },
    ],
    [toggleServiceModal, toggleReturnModal, updateField, deleteReturn]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [{ id: "returnDate", desc: false }],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const fetchReturns = async () => {
      try {
        const response = await axios.get("/api/returns");
        console.log("Fetched Returns:", response.data); // Log the fetched data
        setData(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching returns:", error);
        setLoading(false);
      }
    };
    fetchReturns();
  }, []);

  // Handle changes in filter input
  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  const handleFilterOutRepairedChange = (e) => {
    setFilterOutRepaired(e.target.checked);
    if (e.target.checked) {
      // Set filter to show only 'new' and 'ready for customer' orders
      setFilter("returnStatus", ["new", "returned"]);
    } else {
      // Reset the filter when checkbox is unchecked
      setFilter("returnStatus", "");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 mx-6">
      <h2 className="text-2xl font-semibold mb-4">Returns</h2>

      {/* Filter Inputs */}
      <div className="flex justify-between mb-4 items-center">
        {/* Dropdown to select filter category/column */}
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <option value="">Select Filter Category...</option>
          {columns.map((column) => (
            <option value={column.accessor} key={column.accessor}>
              {column.Header}
            </option>
          ))}
        </select>

        {/* Checkbox to filter out 'repaired' returns */}
        <label className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            checked={filterOutRepaired}
            onChange={handleFilterOutRepairedChange}
            className="form-checkbox text-black rounded border-gray-300 bg-white focus:border-joule focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="text-sm font-medium text-gray-700">
            Hide repaired returns
          </span>
        </label>
      </div>

      {/* Text input for entering filter values */}
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder="Enter filter value..."
        className="border p-2 mt-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />

      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {serviceModalData && (
        <ServiceCell
          isOpen={!!serviceModalData}
          data={serviceModalData}
          source={serviceModalData.source}
          toggleModalVisibility={() => toggleServiceModal()}
          markAsRepaired={markAsRepaired}
        />
      )}
      {returnModalData && (
        <ReturnConfirmationModal
          isOpen={!!returnModalData}
          data={returnModalData}
          source={returnModalData.source}
          toggleModalVisibility={() => toggleReturnModal()}
          markAsReturned={markAsReturned}
        />
      )}
    </div>
  );
};

export default ReturnsTable;
