import React, { useEffect, useState } from "react";
import axios from "axios";

const EmailVerification = () => {
  console.log("EmailVerification component rendered");
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    console.log("Token:", token);

    if (!token) {
      setVerificationStatus("Invalid verification link.");
      return;
    }

    // Send the token to the backend for verification
    axios
      .get(`/api/user/verify-email/${token}`)
      .then((response) => {
        if (response.data.success) {
          setVerificationStatus("Email verified successfully!");
        } else {
          setVerificationStatus(response.data.message || "An error occurred.");
        }
      })
      .catch((error) => {
        setVerificationStatus(
          error.response?.data?.error || "An error occurred."
        );
      });
  }, []);

  return (
    <div className="max-w-md mx-auto text-center p-12 rounded-lg shadow-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Email Verification
      </h2>
      <p
        className={`text-lg ${
          verificationStatus === "Email verified successfully!"
            ? "text-green-500"
            : "text-red-500"
        } mb-6`}
      >
        {verificationStatus}
      </p>
      {verificationStatus === "Email verified successfully!" && (
        <button
          onClick={() => (window.location.href = "/login")}
          className="bg-joule hover:bg-jouledark text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Go to Login
        </button>
      )}
    </div>
  );
};

export default EmailVerification;
