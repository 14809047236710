import React, { useEffect, useState } from "react";
import axios from "axios";

const Dashboard = () => {
  const [matrixData, setMatrixData] = useState({});
  const [loading, setLoading] = useState(true);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const response = await axios.get("/api/inventory");
        const inventoryItems = response.data || [];
        const processedData = processInventoryDataForMatrix(inventoryItems);
        setMatrixData(processedData.data);
        setUniqueStatuses(processedData.statuses);
        setTotals(calculateTotals(processedData.data, processedData.statuses));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory:", error);
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  const processInventoryDataForMatrix = (inventoryItems) => {
    const data = {};
    const statuses = new Set();

    inventoryItems.forEach((item) => {
      const SKU = item.model.SKU;
      const status = item.status.operationalStatus;

      statuses.add(status);

      if (!data[SKU]) {
        data[SKU] = {};
      }

      if (!data[SKU][status]) {
        data[SKU][status] = 1;
      } else {
        data[SKU][status] += 1;
      }
    });

    return { data, statuses: Array.from(statuses).sort() };
  };

  const calculateTotals = (data, statuses) => {
    const columnTotals = {};
    const rowTotals = {};

    // Calculate row totals and prepare columnTotals
    statuses.forEach((status) => (columnTotals[status] = 0));

    Object.keys(data).forEach((SKU) => {
      rowTotals[SKU] = 0;
      statuses.forEach((status) => {
        rowTotals[SKU] += data[SKU][status] || 0;
        columnTotals[status] += data[SKU][status] || 0;
      });
    });

    return { columnTotals, rowTotals };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Helper function to get grid template columns
  const gridTemplateColumns = () => {
    return `minmax(150px, auto) repeat(${uniqueStatuses.length}, minmax(120px, auto)) minmax(100px, auto)`;
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Dashboard</h2>

      {/* Grid container for the fixed header */}
      <div
        className="bg-joule text-white"
        style={{ display: "grid", gridTemplateColumns: gridTemplateColumns() }}
      >
        <div className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
          SKU
        </div>
        {uniqueStatuses.map((status) => (
          <div
            key={status}
            className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
          >
            {status}
          </div>
        ))}
        <div className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
          Total
        </div>
      </div>

      {/* Scrollable body container */}
      <div
        className="overflow-auto"
        style={{
          maxHeight: "75vh",
          display: "grid",
          gridTemplateColumns: gridTemplateColumns(),
        }}
      >
        {Object.keys(matrixData)
          .sort()
          .map((SKU) => (
            <React.Fragment key={SKU}>
              <div className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                {SKU}
              </div>
              {uniqueStatuses.map((status) => (
                <div
                  key={status}
                  className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap"
                >
                  {matrixData[SKU][status] || 0}
                </div>
              ))}
              <div className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                {totals.rowTotals[SKU]}
              </div>
            </React.Fragment>
          ))}
      </div>

      {/* Grid container for the fixed footer */}
      <div
        className="bg-gray-50"
        style={{ display: "grid", gridTemplateColumns: gridTemplateColumns() }}
      >
        <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Total
        </div>
        {uniqueStatuses.map((status) => (
          <div
            key={status}
            className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap"
          >
            {totals.columnTotals[status]}
          </div>
        ))}
        <div className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">
          {Object.values(totals.rowTotals).reduce((acc, cur) => acc + cur, 0)}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
