import React, { useState, useCallback, useEffect } from "react";
import Calendar from "react-calendar";
import axios from "axios";
import { format } from "date-fns"; // Import the date formatting function
import nbLocale from "date-fns/locale/nb"; // Import the Norwegian locale

const WandaBooking = () => {
  const [orderData, setOrderData] = useState({
    storageItemIds: [""], // Add default item ID or leave empty
    type: "DELIVERY", // Either "DELIVERY" or "PICK_UP"
    orderDetails: {
      deliveryDate: "",
      deliveryTimeSlot: {
        from: "",
        to: "",
      },
      contactInfo: {
        firstName: "",
        lastName: "",
        address: {
          street: "",
          city: "",
          postalCode: "",
          countryCode: "NO", // Default to Norway
        },
        email: "",
        mobilePhone: "",
      },
      locale: "no-NO", // Set locale to no-NO
      extraDeliveryInfo: "",
    },
  });
  const [selectedType, setSelectedType] = useState("DELIVERY");
  const [isLoadingAutofill, setIsLoadingAutofill] = useState(false);
  const [isLoadingTimeslots, setIsLoadingTimeslots] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [wandaTimeSlots, setWandaTimeSlots] = useState({});
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [postalCode, setPostalCode] = useState("");
  // eslint-disable-next-line
  const [loadedMonth, setLoadedMonth] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e, path) => {
    let newValue = e.target.value;

    setOrderData((prev) => {
      const updatedData = { ...prev };
      path.reduce((acc, key, index) => {
        if (index === path.length - 1) {
          acc[key] = newValue;
        } else {
          acc[key] = acc[key] || {};
        }
        return acc[key];
      }, updatedData);
      return updatedData;
    });
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setOrderData({ ...orderData, type: type });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSubmit(true); // Start loading
    try {
      await axios.post("/api/wanda-order", orderData);
      setSuccessMessage("Wanda booking successful!");
      setErrorMessage(""); // Clear any previous error messages
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data ||
        "An unknown error occurred";
      setErrorMessage(errorMessage);
      setSuccessMessage(""); // Clear any previous success messages
    }
    setIsLoadingSubmit(false); // Stop loading
  };

  const handleAutofill = async () => {
    setIsLoadingAutofill(true); // Start loading
    try {
      const inventoryResponse = await axios.get(
        `/api/inventory/${serialNumber}`
      );
      const wandaID = inventoryResponse.data.wandaID;

      // Set storageItemIds with wandaID immediately after getting inventoryResponse
      setOrderData((prev) => ({
        ...prev,
        storageItemIds: [wandaID],
      }));

      const itemResponse = await axios.get(`/api/circuly-data/${serialNumber}`);
      const subscriptionId = itemResponse.data.subscription_id;

      const subscriptionResponse = await axios.get(
        `/api/circuly-subscription/${subscriptionId}`
      );
      const contactInfo = subscriptionResponse.data.customer;

      setOrderData((prev) => ({
        ...prev,
        orderDetails: {
          ...prev.orderDetails,
          contactInfo: {
            firstName: contactInfo.address.shipping.first_name,
            lastName: contactInfo.address.shipping.last_name,
            address: {
              street: contactInfo.address.shipping.street,
              city: contactInfo.address.shipping.city,
              postalCode: contactInfo.address.shipping.postal_code,
              countryCode: prev.orderDetails.contactInfo.address.countryCode,
            },
            email: contactInfo.email,
            mobilePhone: contactInfo.phone,
          },
        },
      }));

      // Update the postal code state
      setPostalCode(contactInfo.address.shipping.postal_code);

      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        error.response?.data || "An error occurred during autofill"
      );
    } finally {
      setIsLoadingAutofill(false); // Stop loading
    }
  };

  const fetchWandaTimeslots = useCallback(async () => {
    if (!postalCode) return;

    setIsLoadingTimeslots(true); // Start loading
    const month = (currentMonth.getMonth() + 1).toString();
    const year = currentMonth.getFullYear().toString();
    const monthYear = `${year}-${month}`;

    try {
      const requestBody = {
        postalCode,
        orderType: selectedType,
        year,
        month,
      };

      const response = await axios.post("/api/wanda-timeslots", requestBody);

      const filteredTimeSlots = Object.entries(response.data).reduce(
        (acc, [date, slots]) => {
          acc[date] = slots.filter(
            (slot) => slot.isOpen && slot.key !== "0900-2200"
          );
          return acc;
        },
        {}
      );

      setWandaTimeSlots(filteredTimeSlots);
      setLoadedMonth(monthYear); // Update the loaded month
    } catch (error) {
      setErrorMessage("Error fetching timeslots");
      setWandaTimeSlots({});
    } finally {
      setIsLoadingTimeslots(false); // Stop loading
    }
  }, [postalCode, currentMonth, selectedType]);

  // Use a useEffect hook to trigger fetching timeslots when postalCode changes
  useEffect(() => {
    if (postalCode.length === 4) {
      fetchWandaTimeslots();
    }
  }, [postalCode, fetchWandaTimeslots]);

  const isSelectable = (date) => {
    const dateString = format(date, "yyyy-MM-dd");
    const availableTimeSlots = wandaTimeSlots[dateString];
    return availableTimeSlots && availableTimeSlots.length > 0;
  };

  const handleDateChange = (date) => {
    if (isSelectable(date)) {
      setSelectedDate(date);
      const formattedDate = format(date, "yyyy-MM-dd");

      const availableTimeSlots = wandaTimeSlots[formattedDate] || [];
      if (availableTimeSlots.length === 1 && availableTimeSlots[0].isOpen) {
        setOrderData((prev) => ({
          ...prev,
          orderDetails: {
            ...prev.orderDetails,
            deliveryDate: formattedDate,
            deliveryTimeSlot: availableTimeSlots[0],
          },
        }));
      } else {
        setOrderData((prev) => ({
          ...prev,
          orderDetails: {
            ...prev.orderDetails,
            deliveryDate: formattedDate,
            deliveryTimeSlot: { from: "", to: "" },
          },
        }));
      }
    }
  };

  const handleMonthChange = (date) => {
    setCurrentMonth(date);
    setSelectedDate(null);
    setLoadedMonth(null);
  };

  const handleTimeslotSelect = (timeslot) => {
    setOrderData((prev) => ({
      ...prev,
      orderDetails: {
        ...prev.orderDetails,
        deliveryTimeSlot: timeslot,
      },
    }));
  };

  const handlePostalCodeChange = (e) => {
    const newPostalCode = e.target.value;
    setPostalCode(newPostalCode);
    setOrderData((prev) => ({
      ...prev,
      orderDetails: {
        ...prev.orderDetails,
        contactInfo: {
          ...prev.orderDetails.contactInfo,
          address: {
            ...prev.orderDetails.contactInfo.address,
            postalCode: newPostalCode,
          },
        },
      },
    }));
    setLoadedMonth(null); // Reset loaded month
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full h-full mx-auto p-12 rounded-lg bg-white max-h-[calc(100vh-10vh)] min-h-[calc(100vh-10vh)]"
    >
      <h1 className="text-3xl font-bold mb-8 text-center text-black">
        Wanda Booking
      </h1>
      <div className="flex flex-wrap md:flex-nowrap mb-6 space-y-6 md:space-y-0 md:space-x-6">
        <div className="w-full md:w-1/2 p-4 border shadow-lg rounded-lg space-y-4 max-h-[calc(100vh-20vh)] min-h-[calc(100vh-20vh)]">
          <div className="flex space-x-4 items-center">
            <div className="flex flex-col flex-grow space-y-2">
              <h2 className="text-lg font-semibold text-black">
                Input Serial Number
              </h2>
              <div className="flex items-center relative">
                <input
                  type="text"
                  placeholder="Enter Serial Number"
                  className="flex-grow p-3 border rounded-l-lg"
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
                <button
                  type="button"
                  className="bg-joule text-white px-6 rounded-r-lg hover:bg-gray-600 ml-2"
                  style={{ height: "46px" }}
                  onClick={handleAutofill}
                  disabled={isLoadingAutofill}
                >
                  {isLoadingAutofill ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                  ) : (
                    "Autofill"
                  )}
                </button>
              </div>
            </div>
            <div className="flex flex-col flex-grow space-y-2">
              <h2 className="text-lg font-semibold text-black">
                Input Postal Code
              </h2>
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Enter Postal Code"
                  className="flex-grow p-3 border rounded-lg"
                  value={postalCode}
                  onChange={handlePostalCodeChange}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2 relative">
            <h2 className="text-lg font-semibold text-black">Order Type</h2>
            <div className="flex justify-around">
              <button
                type="button"
                onClick={() => handleTypeSelect("DELIVERY")}
                className={`w-1/2 px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                  selectedType === "DELIVERY"
                    ? "bg-joule text-white shadow-md focus:scale-105"
                    : "bg-gray-100 text-jouletext2"
                } hover:bg-joule hover:text-white`}
              >
                DELIVERY
              </button>
              <button
                type="button"
                onClick={() => handleTypeSelect("PICK_UP")}
                className={`w-1/2 px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                  selectedType === "PICK_UP"
                    ? "bg-joule text-white shadow-md focus:scale-105"
                    : "bg-gray-100 text-jouletext2"
                } hover:bg-joule hover:text-white`}
              >
                PICK UP
              </button>
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg relative">
            <h2 className="text-center text-lg font-semibold text-black mb-4">
              Select a Date
            </h2>
            {isLoadingTimeslots && (
              <div className="absolute inset-0 flex justify-center items-center bg-gray-200 bg-opacity-50">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-joule"></div>
              </div>
            )}
            <div className="flex justify-center w-full">
              <div className="w-full max-w-3xl">
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  selectRange={false}
                  tileDisabled={({ date }) => !isSelectable(date)}
                  minDetail="month"
                  tileClassName={({ date }) =>
                    isSelectable(date) ? "" : "not-selectable"
                  }
                  next2Label={null}
                  prev2Label={null}
                  locale={nbLocale}
                  onActiveStartDateChange={({ activeStartDate }) =>
                    handleMonthChange(activeStartDate)
                  }
                  className="w-full"
                  style={{ transform: "scale(1.2)", transformOrigin: "center" }} // Adjust the scale as needed
                />
              </div>
            </div>

            {selectedDate &&
              wandaTimeSlots[format(selectedDate, "yyyy-MM-dd")] && (
                <div className="mt-4">
                  <h2 className="text-center text-lg font-semibold text-black mb-4">
                    Available Timeslots
                  </h2>
                  <div className="flex flex-wrap justify-center gap-3">
                    {wandaTimeSlots[format(selectedDate, "yyyy-MM-dd")]?.map(
                      (slot) => {
                        const isSelected =
                          orderData.orderDetails.deliveryTimeSlot &&
                          orderData.orderDetails.deliveryTimeSlot.key ===
                            slot.key;

                        return (
                          <button
                            key={slot.key}
                            type="button"
                            onClick={() => handleTimeslotSelect(slot)}
                            className={`w-1/2 px-4 py-2 rounded-lg text-lg transition-colors duration-200 ${
                              isSelected
                                ? "bg-joule text-white shadow-md focus:scale-105"
                                : "bg-gray-100 text-jouletext2"
                            } hover:bg-joule hover:text-white`}
                          >
                            {slot.from} - {slot.to}
                          </button>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="w-full md:w-1/2 p-4 border shadow-lg rounded-lg max-h-[calc(100vh-20vh)] min-h-[calc(100vh-20vh)]">
          <h2 className="text-lg font-semibold text-black mb-4">
            Booking Data
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">First Name:</label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.firstName}
                onChange={(e) =>
                  handleInputChange(e, [
                    "orderDetails",
                    "contactInfo",
                    "firstName",
                  ])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">Last Name:</label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.lastName}
                onChange={(e) =>
                  handleInputChange(e, [
                    "orderDetails",
                    "contactInfo",
                    "lastName",
                  ])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">Street:</label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.address.street}
                onChange={(e) =>
                  handleInputChange(e, [
                    "orderDetails",
                    "contactInfo",
                    "address",
                    "street",
                  ])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">City:</label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.address.city}
                onChange={(e) =>
                  handleInputChange(e, [
                    "orderDetails",
                    "contactInfo",
                    "address",
                    "city",
                  ])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">Postal Code:</label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg bg-gray-300"
                value={orderData.orderDetails.contactInfo.address.postalCode}
                readOnly
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">Email:</label>
              <input
                type="email"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.email}
                onChange={(e) =>
                  handleInputChange(e, ["orderDetails", "contactInfo", "email"])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">Mobile Phone:</label>
              <input
                type="tel"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.contactInfo.mobilePhone}
                onChange={(e) =>
                  handleInputChange(e, [
                    "orderDetails",
                    "contactInfo",
                    "mobilePhone",
                  ])
                }
              />
            </div>

            <div className="flex flex-col sm:flex-row items-center">
              <label className="sm:w-1/3 mb-2 sm:mb-0">
                Extra Delivery Info:
              </label>
              <input
                type="text"
                className="flex-grow p-3 border rounded-lg"
                value={orderData.orderDetails.extraDeliveryInfo}
                onChange={(e) =>
                  handleInputChange(e, ["orderDetails", "extraDeliveryInfo"])
                }
              />
            </div>
            <button
              type="submit"
              className="w-full md:w-auto bg-joule hover:bg-gray-600 text-white py-3 px-6 rounded-lg flex justify-center items-center"
              disabled={isLoadingSubmit}
            >
              {isLoadingSubmit ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                  Laster...
                </>
              ) : (
                "Submit Order"
              )}
            </button>
          </div>
          {successMessage && (
            <p className="text-green-500 text-center mt-4">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-red-500 text-center mt-4">{errorMessage}</p>
          )}
        </div>
      </div>
    </form>
  );
};

export default WandaBooking;
