import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/sidebar";
import Inventory from "./pages/Inventory";
import TodoList from "./pages/TodoList";
import ServiceBookings from "./pages/ServiceBookings";
import Returns from "./pages/Returns";
import NewOrders from "./pages/NewOrders";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/Registerpage";
import EmailVerification from "./pages/EmailVerification";
import { useAuth } from "./components/AuthProvider"; // Just useAuth since AuthProvider is in index.js
import axios from "axios";
import ReactModal from "react-modal";
import WandaBooking from "./pages/WandaBooking";
import ItemPage from "./pages/ItemPage";
import BikeFlowDashboard from "./pages/BikeFlowDashboard";
import IncomingBikes from "./pages/IncomingBikes";
import OutgoingBikes from "./pages/OutgoingBikes";
import MissingItems from "./pages/MissingItems";
import Dashboard from "./pages/Dashboard";

ReactModal.setAppElement("#root");

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.withCredentials = true;

function ProtectedRoute({ children }) {
  console.log("ProtectedRoute triggered");
  const { isAuthenticated, updateLastVisited } = useAuth();
  if (!isAuthenticated) {
    updateLastVisited(window.location.pathname);
  }
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function App() {
  console.log("App component rendered");
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <LayoutWrapper>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/todo-list"
            element={
              <ProtectedRoute>
                <TodoList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/service-bookings"
            element={
              <ProtectedRoute>
                <ServiceBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/returns"
            element={
              <ProtectedRoute>
                <Returns />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-orders"
            element={
              <ProtectedRoute>
                <NewOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/missing-items"
            element={
              <ProtectedRoute>
                <MissingItems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bike-flow-dashboard"
            element={
              <ProtectedRoute>
                <BikeFlowDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/incoming-bikes"
            element={
              <ProtectedRoute>
                <IncomingBikes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/outgoing-bikes"
            element={
              <ProtectedRoute>
                <OutgoingBikes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wanda-booking"
            element={
              <ProtectedRoute>
                <WandaBooking />
              </ProtectedRoute>
            }
          />
          <Route
            path="/item-page/:serialNumber"
            element={
              <ProtectedRoute>
                <ItemPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </LayoutWrapper>
    </BrowserRouter>
  );
}

function LayoutWrapper({ children }) {
  console.log("LayoutWrapper component rendered");
  const location = useLocation();

  const showSidebar = !["/login", "/register", "/verify-email"].some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <div className="flex min-h-screen">
      {showSidebar && <Sidebar />}
      <div className="flex-1 overflow-x-hidden">{children}</div>
    </div>
  );
}

export default App;
