import React, { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";

//Modal to send mail to customers with missing items when returning the bike
const SendMailModal = ({ isOpen, closeModal, rowData, onStatusUpdate }) => {
  const [note, setNote] = useState("");

  const handleSendMail = async () => {
    try {
      await axios.post("/api/send-missing-item-mail", {
        email: rowData.email,
        note: note,
      });
      // Update status after sending the email
      onStatusUpdate(rowData._id, "customer notified");
      closeModal();
      // Optionally, you can add logic to show a success message or refresh part of the UI
    } catch (error) {
      console.error("Error sending mail:", error);
      // Optionally, handle the error in the UI (like showing an error message)
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Send Mail"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="bg-white shadow-lg rounded-lg px-10 py-8 mb-6 border-2 border-gray-600">
        <h2 className="text-gray-900 text-2xl font-semibold text-center mb-6">
          Send Mail to Customer
        </h2>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-semibold mb-2"
            htmlFor="note"
          >
            Note
          </label>
          <textarea
            className="border-gray-600 shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-joule focus:ring-1 focus:ring-joule"
            id="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Type your message here..."
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <button
            onClick={handleSendMail}
            className="bg-joule text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-gray-900 focus:outline-none focus:shadow-outline transition-colors"
          >
            Send Mail
          </button>
          <button
            onClick={closeModal}
            className="bg-red-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:shadow-outline transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default SendMailModal;
