import React, { createContext, useContext, useState, useEffect } from "react"; // Added useEffect
import axios from "axios";

const AuthContext = createContext();

// The AuthProvider component is used to manage user authentication. It is meant to be used as a wrapper component around all components that require authentication.

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastVisited, setLastVisited] = useState("/inventory"); // default to /inventory

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        "/api/user/login",
        { email, password },
        { withCredentials: true }
      );
      if (response.data.message === "Logged in") {
        setUser(response.data.user);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      // Throw an error that can be caught in the LoginPage
      throw new Error(
        error.response?.data.error ||
          "Login failed. Please check your credentials."
      );
    }
  };

  const checkAuth = async () => {
    try {
      const response = await axios.get("/api/user/check-auth", {
        withCredentials: true,
      });
      if (response.data.isAuthenticated) {
        setUser(response.data.user); // Also set the user details if authenticated
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to check authentication:", error);
      setUser(null);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const updateLastVisited = (path) => {
    setLastVisited(path);
  };

  const logout = async () => {
    try {
      await axios.post("/api/user/logout", {}, { withCredentials: true });
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        lastVisited,
        login,
        logout,
        updateLastVisited,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
