import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./components/AuthProvider"; // Import AuthProvider
import reportWebVitals from "./reportWebVitals";

function Root() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />); // Render Root instead of App

reportWebVitals();
