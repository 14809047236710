import React, { useState } from "react";
import Calendar from "react-calendar";
import "../calendar.css";
import { format, parseISO } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

function DateCell({ date, updateField, id, fieldToUpdate }) {
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateChange = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    updateField(id, fieldToUpdate, formattedDate);
    setShowCalendar(false);
  };

  const toggleModalVisibility = () => {
    setShowCalendar(!showCalendar);
  };

  const parsedDate = date ? parseISO(date) : null;
  const isValidDate =
    parsedDate && !isNaN(parsedDate) && parsedDate instanceof Date;

  return (
    <div onClick={toggleModalVisibility} className="cursor-pointer">
      <ReactModal
        isOpen={showCalendar}
        onRequestClose={toggleModalVisibility}
        contentLabel="Calendar Modal"
        className="modal-content"
        overlayClassName={{
          base: "modal-overlay",
        }}
        shouldCloseOnOverlayClick={true}
      >
        <CalendarComponent
          data={{ date: parsedDate }}
          setData={handleDateChange}
        />
      </ReactModal>

      <div>
        {isValidDate ? (
          <>
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {format(parsedDate, "PP", { locale: nbLocale })}
          </>
        ) : (
          "Set Date"
        )}
      </div>
    </div>
  );
}

function CalendarComponent({ data, setData }) {
  const handleDateChange = (date) => {
    setData(date);
  };

  const handleContainerClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      onClick={handleContainerClick}
      className="text-center bg-white rounded-lg p-6 shadow-lg z-45"
    >
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Velg dato</h2>
      <div className="p-6 bg-white rounded-lg shadow-sm mx-auto flex justify-center border border-gray-200">
        <Calendar
          onChange={handleDateChange}
          value={data.date || new Date()}
          selectRange={false}
          minDetail="month"
          next2Label={null}
          prev2Label={null}
          locale={nbLocale}
        />
      </div>
      {data.date && (
        <p className="mt-4 text-gray-700">
          Valgt dato: {format(data.date, "PP", { locale: nbLocale })}
        </p>
      )}
    </div>
  );
}

export { DateCell };
