import React, { useState } from "react";
import axios from "axios";
import TopBar from "../components/TopBar"; // Assuming you have exported TopBar from a file

// This code is used to register a new user. It takes the user's email and password,
// and submits them to the server. The server then creates a new user in the database
// using the provided email and password. If the email is already in use, the server
// responds with an error message, and the error is logged to the console.

// handleSubmit is called when the user clicks the "Register" button on the registration page.
// It first checks if the passwords match. If they do, it sends a POST request to the server,
// with the user's email and password. If the email is already in use, the server responds
// with an error message, and the error is logged to the console. If the registration is
// successful, the user is redirected to the login page. If the registration fails, the error
// is logged to the console.

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (password !== confirmPassword) {
      setErrorMessage("Passwords don't match!");
      return;
    }

    if (!email.endsWith("@joule.no")) {
      setErrorMessage("Email must end with @joule.no");
      return;
    }

    try {
      const response = await axios.post("/api/user/register", {
        email,
        password,
      });

      if (response.status === 200) {
        // This line has been changed
        setSuccessMessage(
          "Registration successful! You should receive an email with a link to verify your email address."
        );
      }
    } catch (error) {
      console.error("Registration failed:", error);
      if (error.response) {
        setErrorMessage(
          error.response.data.error ||
            "Registration failed. Please try again later."
        );
      } else {
        setErrorMessage("Registration failed. Please try again later.");
      }
    }
  };

  return (
    <div>
      <TopBar />
      <div className="flex bg-joule h-screen w-screen flex-col items-center space-y-6 mb-auto">
        <h2 className="text-center text-lg pt-8 font-century-gothic text-jouletext">
          REGISTER
        </h2>
        <div className="bg-white px-4 py-8 rounded-lg shadow-md sm:w-1/3">
          {successMessage && (
            <div className="text-green-600 text-center mb-4">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="text-red-600 text-center mb-4">{errorMessage}</div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email (Username)"
              className="border-2 border-gray-300 w-full py-2 px-4 rounded-md"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="border-2 border-gray-300 w-full py-2 px-4 rounded-md"
              required
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="border-2 border-gray-300 w-full py-2 px-4 rounded-md"
              required
            />
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="w-32 bg-white tracking-wide text-black font-bold rounded-lg border-black hover:bg-gray-500 hover:text-white shadow-md py-2 px-6 inline-flex items-center"
              >
                <span className="mx-auto">Register</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
