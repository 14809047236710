//When a person clicks on a specific item within the Inventory overview, they will be taken to this page.
//The info on this page will be retrieved from the database using serial_number, the Wanda API and the Circuly API.
//This page will display all the information about the item.
//The page will be displayed in five sections
//The first section is the data we have about the item from our database
//The second section is the data we have about the item from the Wanda API: Get request on the item's wandaID
//The third section is the data we have about the item from the Circuly API: Get request on the item's ID and furthermore the Subscription ID
//The fourth section is a table with all the services the item has been through from the service database
//The fifth section is a list of all the bookings the item has been through from the booking database

// ItemPage.jsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ItemPage = () => {
  const { serialNumber } = useParams();
  const [bikeData, setBikeData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [circulyData, setCirculyData] = useState(null);
  const [wandaData, setWandaData] = useState(null);
  const [operationalStatus, setOperationalStatus] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from the bike inventory
        const bikeResponse = await axios.get(`/api/inventory/${serialNumber}`);
        setBikeData(bikeResponse.data);
        setOperationalStatus(
          bikeResponse.data?.status?.operationalStatus || ""
        );

        // Fetch data from the Circuly API
        const circulyResponse = await axios.get(
          `/api/circuly-data/${serialNumber}`
        );
        setCirculyData(circulyResponse.data);

        // Fetch data from the Wanda API
        if (bikeResponse.data && bikeResponse.data.wandaID) {
          const wandaResponse = await axios.get(
            `/api/wanda-item/${bikeResponse.data.wandaID}`
          );
          setWandaData(wandaResponse.data);
        }

        // Fetch maintenance data from service DB
        const maintenanceResponse = await axios.get(
          `/api/services/serial/${serialNumber}`
        );
        setMaintenanceData(maintenanceResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [serialNumber]);

  //Function to change operational status
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setOperationalStatus(newStatus);
    try {
      await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
        operationalStatus: newStatus,
      });
      setBikeData((prevData) => ({
        ...prevData,
        status: { ...prevData.status, operationalStatus: newStatus },
      }));
    } catch (error) {
      console.error("Error updating operational status:", error);
    }
  };

  //Function to create overleveringsordre (to change wanda status)
  const createWandaOrder = async () => {
    const today = new Date();
    const deliveryDate = new Date(today.setDate(today.getDate() + 14))
      .toISOString()
      .split("T")[0];

    const orderData = {
      storageItemIds: [bikeData.wandaID],
      type: wandaData.state === "STORED" ? "DELIVERY" : "PICK_UP",
      orderDetails: {
        deliveryDate,
        deliveryTimeSlot: {
          from: "0900",
          to: "1700",
        },
        contactInfo: {
          firstName: "Joule",
          lastName: "AS",
          address: {
            street: "Økernveien 9",
            city: "Oslo",
            postalCode: "0653",
            countryCode: "NO",
          },
          email: "haavard.traa@joule.no",
          mobilePhone: "+4795949356",
        },
        locale: "no-NO",
        extraDeliveryInfo: "overleveringsordre",
      },
    };

    try {
      const response = await axios.post("/api/wanda-order", orderData);
      console.log("Wanda order created:", response.data);
    } catch (error) {
      console.error("Error creating Wanda order:", error);
    }
  };

  if (!bikeData) {
    return <div>Loading bike data...</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-4 overflow-auto max-h-screen">
      <div className="flex flex-col lg:flex-row gap-4">
        {/* Left Side: Database Information */}
        <div className="flex-grow bg-white shadow-md rounded px-8 pt-1 pb-1 mb-4">
          <h3 className="text-gray-700 text-lg font-semibold mb-4">
            Database Information - Serial Number: {serialNumber}
          </h3>
          <table className="min-w-full table-auto">
            <tbody>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Frame Number</th>
                <td className="px-4 py-2">{bikeData.frameNumber}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Brand</th>
                <td className="px-4 py-2">{bikeData.model?.brand}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Type</th>
                <td className="px-4 py-2">{bikeData.model?.type}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Year</th>
                <td className="px-4 py-2">{bikeData.model?.year}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Size</th>
                <td className="px-4 py-2">{bikeData.model?.size}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Innsteg</th>
                <td className="px-4 py-2">{bikeData.model?.innsteg}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">SKU</th>
                <td className="px-4 py-2">{bikeData.model?.SKU}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Lock Key</th>
                <td className="px-4 py-2">{bikeData.keyNumber?.lockKey}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Key</th>
                <td className="px-4 py-2">{bikeData.keyNumber?.batteryKey}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Operational Status</th>
                <td className="px-4 py-2">
                  <select
                    value={operationalStatus}
                    onChange={handleStatusChange}
                    className="border p-1 rounded"
                  >
                    <option value="in stock">in stock</option>
                    <option value="marketing">marketing</option>
                    <option value="new">new</option>
                    <option value="ready for customer">
                      ready for customer
                    </option>
                    <option value="repair-booked">repair-booked</option>
                    <option value="repair-new order">repair-new order</option>
                    <option value="repair-returned">repair-returned</option>
                    <option value="stolen">stolen</option>
                    <option value="with customer">with customer</option>
                    <option value="summer freeze">summer freeze</option>
                    <option value="winter freeze">winter freeze</option>
                  </select>
                </td>
              </tr>

              <tr className="border-b">
                <th className="px-4 py-2 text-left">Last Service</th>
                <td className="px-4 py-2">
                  {bikeData.status?.serviceStatus?.lastService}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Last KM</th>
                <td className="px-4 py-2">
                  {bikeData.status?.serviceStatus?.lastKm}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Capacity</th>
                <td className="px-4 py-2">
                  {bikeData.status?.batteryStatus?.batteryCapacity}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Battery Charge Cycles</th>
                <td className="px-4 py-2">
                  {bikeData.status?.batteryStatus?.batteryChargeCycles}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Country</th>
                <td className="px-4 py-2">{bikeData.position?.Country}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">City</th>
                <td className="px-4 py-2">{bikeData.position?.City}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Warehouse</th>
                <td className="px-4 py-2">{bikeData.position?.Warehouse}</td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 text-left">Warehouse position</th>
                <td className="px-4 py-2">
                  {bikeData.position?.WarehouseSection}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Right Side: Circuly and Wanda Information */}
        <div className="flex-grow bg-white shadow-md rounded px-8 pt-1 pb-1 mb-4">
          {circulyData && (
            <div className="mb-4">
              <h3 className="text-gray-700 text-lg font-semibold mb-4">
                Circuly Information
              </h3>
              <table className="min-w-full table-auto">
                <tbody>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Subscription ID</th>
                    <td className="px-4 py-2">{circulyData.subscription_id}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Rental Status</th>
                    <td className="px-4 py-2">{circulyData.location_status}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Customer ID</th>
                    <td className="px-4 py-2">{circulyData.customer_id}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Customer Name</th>
                    <td className="px-4 py-2">{circulyData.customer_name}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Last Updated</th>
                    <td className="px-4 py-2">{circulyData.updated_at}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {wandaData && (
            <div>
              <h3 className="text-gray-700 text-lg font-semibold mt-2 mb-4">
                Wanda Information
              </h3>
              <table className="min-w-full table-auto">
                <tbody>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Name</th>
                    <td className="px-4 py-2">{wandaData.name}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Description</th>
                    <td className="px-4 py-2">{wandaData.description}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">City</th>
                    <td className="px-4 py-2">{wandaData.city}</td>
                  </tr>
                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">State</th>
                    <td className="px-4 py-2">{wandaData.state}</td>
                    <td className="px-4 py-2">
                      <button
                        onClick={createWandaOrder}
                        className="bg-joule text-white px-4 py-2 rounded hover:bg-jouledark"
                      >
                        {wandaData.state === "STORED"
                          ? "Mark as With Customer"
                          : "Mark as Stored"}
                      </button>
                    </td>
                  </tr>

                  <tr className="border-b">
                    <th className="px-4 py-2 text-left">Wanda ID</th>
                    <td className="px-4 py-2">{bikeData.wandaID}</td>
                  </tr>
                  {/* Add other fields as needed */}
                </tbody>
              </table>
              <h3 className="text-gray-700 text-lg font-semibold mt-6 mb-4">
                Wanda ordre
              </h3>
              <table className="min-w-full table-auto">
                <tbody>
                  {wandaData.pendingOrderId && (
                    <tr className="border-b">
                      <th className="px-4 py-2 text-left">Ordre ID</th>
                      <td className="px-4 py-2">{wandaData.pendingOrderId}</td>
                    </tr>
                  )}

                  {wandaData.pendingOrderType && (
                    <tr className="border-b">
                      <th className="px-4 py-2 text-left">Type Ordre</th>
                      <td className="px-4 py-2">
                        {wandaData.pendingOrderType}
                      </td>
                    </tr>
                  )}

                  {wandaData.pendingOrderDetails && (
                    <>
                      <tr className="border-b">
                        <th className="px-4 py-2 text-left">Dato</th>
                        <td className="px-4 py-2">
                          {wandaData.pendingOrderDetails.deliveryDate ||
                            "Ikke tilgjengelig"}
                        </td>
                      </tr>
                      <tr className="border-b">
                        <th className="px-4 py-2 text-left">Tidspunkt fra</th>
                        <td className="px-4 py-2">
                          {wandaData.pendingOrderDetails.deliveryTimeSlot
                            ?.from || "Ikke tilgjengelig"}
                        </td>
                      </tr>
                      <tr className="border-b">
                        <th className="px-4 py-2 text-left">Tidspunkt til</th>
                        <td className="px-4 py-2">
                          {wandaData.pendingOrderDetails.deliveryTimeSlot?.to ||
                            "Ikke tilgjengelig"}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      {/* Maintenance Information */}
      <div className="bg-white shadow-md rounded px-8 pt-1 pb-1 mb-4 mt-4">
        <h3 className="text-gray-700 text-lg font-semibold mb-4">
          Maintenance Records
        </h3>
        <table className="min-w-full table-auto">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">Type</th>
              <th className="px-4 py-2 text-left">KM</th>
              <th className="px-4 py-2 text-left">Note</th>
            </tr>
          </thead>
          <tbody>
            {maintenanceData.length > 0 ? (
              maintenanceData.map((service, index) => (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">
                    {new Date(service.serviceDate).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">{service.serviceType}</td>
                  <td className="px-4 py-2">{service.km}</td>
                  <td className="px-4 py-2">{service.note}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-4 py-2 text-center">
                  No maintenance records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ItemPage;
